<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.perfilUser')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'perfil-afiliado-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="model.name"
                  :disabled="model.name === 'Soporte' || model.name === 'Propietario'"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  :placeholder="$t('lbl.name')"
                  hide-details
                ></v-text-field>
                <v-switch
                  v-model="model.active"
                  :label="$t('lbl.active')"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <v-textarea
                  v-model="model.description"
                  :label="$t('lbl.description')"
                  outlined
                  rows="3"
                  row-height="25"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row v-if="is_nivel_propietario && (model.name === 'Soporte' || model.name === 'Propietario')">
              <v-col
                cols="12"
                md="12"
              >
                <v-card max-height="400px">
                  <v-card-title>{{ $t('lbl.dataAdmin') }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="email"
                          :label="$t('lbl.email')"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              @click="promover()"
                            >
                              <v-icon
                                v-bind="attrs"
                                small
                                v-on="on"
                              >
                                {{ icons.mdiUndo }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('lbl.promover') }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        sm="4"
                      >
                        <v-select
                          v-model="user"
                          :items="users"
                          item-text="email"
                          item-value="email"
                          :label="$t('lbl.email')"
                          outlined
                          dense
                          hide-details
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!--<v-row>
              <v-col cols="12">
                <Permisos
                  :exclude="['perfil_afiliados', 'user_register']"
                />
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
// import Permisos from '@/views/utils/permisos/List.vue'
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate, mdiUndo,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,

    // Permisos,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiUndo,
      },
      model: {},
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: null,
      users: [],
      email: null,
      loading: false,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('perfil-afiliado-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState(['permisosItem']),
  },
  created() {
    this.profile()
    this.loadPermisos()
    this.getItem()
  },
  methods: {
    ...mapMutations(['editPermisosItem']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getItem() {
      if (sessionStorage.getItem('perfil-afiliado-id') !== null) {
        const id = sessionStorage.getItem('perfil-afiliado-id')
        this.axios
          .get(`perfil_afiliado/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.email = this.model.emailSuperAdmin
            this.model.users.forEach(element => {
              if (element.email !== this.email) {
                this.users.push(element)
              }
            })
            if (this.model.permisos) {
              if (this.model.permisos.abilities[0] === '*') {
                // cargar los permisos
                this.axios
                  .get('permiso?per_page=1000', {
                    headers: {
                      Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                    },
                  })
                  .then(per => {
                    this.permisos = per.data.data
                  })
                  .finally(() => {
                    const perm = []
                    this.permisos.forEach(element => {
                      perm.push(element.ability)
                    })
                    this.editPermisosItem(perm)
                  })
              } else {
                this.loadPermisos()
                if (this.model.permisos) {
                  this.editPermisosItem(this.model.permisos.abilities)
                } else {
                  this.editPermisosItem([])
                }
              }
            } else {
              this.editPermisosItem([])
            }
          })
      } else {
        this.editPermisosItem([])
      }
    },
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
    save() {
      if (this.model.name) {
        this.loading = true
        const json = {
          name: this.model.name,
          description: this.model.description,
          active: this.model.active,

          // permisos: this.$store.state.app.permisosItem.join(','),

          email: this.email,
          // eslint-disable-next-line no-nested-ternary
          nivel: this.model.name === 'Soporte' ? -1 : this.model.name === 'Propietario' ? 0 : null,
        }
        if (sessionStorage.getItem('perfil-afiliado-id') === null) {
          this.axios
            .post('perfil_afiliado', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.userExist', { email: this.email }))
                }
              } else {
                sessionStorage.removeItem('perfil-afiliado-id')
                this.$router.push({ name: 'perfil-afiliado-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`perfil_afiliado/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.userExist', { email: this.email }))
                }
              } else {
                sessionStorage.removeItem('perfil-afiliado-id')
                this.$router.push({ name: 'perfil-afiliado-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    promover() {
      if (this.user) {
        this.email = this.user
        this.user = null
      }
    },
  },
}
</script>
